import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import { formatNumber } from "_common/utils/formatValue";

export default function ShareLink() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("i_want_to_share")} isBack />
      <div className="sbui-card my-10">
        <div className="sbui-card-content">
          <div className="total">
            <p>Tổng hoa hồng</p>
            <h5>
              {formatNumber(user?.total_commission)}
              <span>USD</span>
            </h5>
            <p>
              {`Chia sẻ liên kết này với bạn bè của bạn và nhận phần thưởng lên đến {money}.`}
            </p>
          </div>
          <div className="mt-6">
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("share_link")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    className="sbui-input sbui-input--medium"
                    value={user.link_ref}
                  />
                  <button
                    type="button"
                    className="btn btn-copy"
                    onClick={() => handleCopyLink(user?.link_ref)}
                  >
                    <span>{i18next.t("copy")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
