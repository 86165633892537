import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { gameBetItemType, profitText } from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import { useSearchParams } from "react-router-dom";

function ModalBonus({ open, data, onClose }) {
  const {
    authStore: { general },
  } = useStores();
  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";

  if (!open) return <div />;

  return (
    <Portal>
      <div
        className="modal modal-bet"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <div className="tabs">
              <div className={classNames("tab")}>Hợp đồng</div>
              <div className={classNames("tab active")}>Tuỳ chọn</div>
            </div>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="contract-main">
            <div className="content">
              <div className="content-title ">
                <div className="content-title-left">
                  <div className="left-number text-uppercase">
                    {symbol?.split("usdt")?.[0]}/USD
                  </div>
                  <div className="left-icon left-icon1">
                    {formatNumber(data?.close, "0.00")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="list-bet">
              <div className={classNames("item active")}>
                {data?.status === "win" && (
                  <h5 className="text-green">
                    +{formatNumber(data?.amount_payback)}
                  </h5>
                )}
                {data?.status === "lose" && (
                  <h5 className="text-red">
                    -{formatNumber(data?.amount_lose)}
                  </h5>
                )}
                <p>
                  Tỷ suất sinh lời{" "}
                  {formatNumber(profitText[data.type], "0,0.00")}%
                </p>
              </div>
            </div>
            <div className="content">
              <div className="table-info">
                <div className="item">
                  <p>{i18next.t("purchase_quantity")}</p>
                  <p>${formatNumber(data?.amount, "0,0.00")}</p>
                </div>
                <div className="item">
                  <p>{i18next.t("open_price")}</p>
                  <p>{formatNumber(data?.open)}</p>
                </div>

                <div className="item">
                  <p>{i18next.t("fee_trade")}</p>
                  <p>
                    $
                    {formatNumber(
                      Number(get(data, "amount", 0)) / 100,
                      "0,0.00"
                    )}
                  </p>
                </div>
                <div className="item">
                  <p>{i18next.t("estimated_earnings")}</p>
                  <p>${formatNumber(data?.amount_payback, "0,0.00")}</p>
                </div>
                <div className="item">
                  <p>{i18next.t("close_price")}</p>
                  <p>{formatNumber(data?.close, "0,0.00")}</p>
                </div>
                <div className="item">
                  <p>{i18next.t("direction")}</p>
                  <p className={data?.bet_game}>
                    {i18next.t(gameBetItemType[data?.bet_game])}
                  </p>
                </div>
              </div>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={onClose}
              >
                {i18next.t("continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalBonus;
