import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import icon9 from "assets/img/icon/icon9.png";
import icon10 from "assets/img/icon/icon10.png";
import icon8 from "assets/img/icon/icon8.png";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

const UserBalance = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { display_balance, user, tokens },
  } = useStores();

  return (
    <div className="new-feature">
      <div className="left-bg1" />
      <div className="left-bg2" />
      <div className="titles">
        <FontAwesomeIcon icon={faWallet} />
        <span>{i18next.t("balance")}</span>
      </div>
      <div className="banalce-info">
        <h5>{formatCurrency(user?.balance?.amount)}</h5>
        <span className="ltr">{formatNumber(user?.balance?.change)}%</span>
      </div>
      <div className="infos">
        <div className="info">
          <p>{i18next.t("total_earning")}</p>
          <h5 className="info-content">
            ${formatNumber(user?.total_profit_today, "0,0.00")}
          </h5>
        </div>
        <div className="info">
          <p>{i18next.t("total_quantification")}</p>
          <h5 className="info-content">
            ${formatNumber(user?.balance_fund, "0,0.00")}
          </h5>
        </div>
        <div className="info">
          <p>{i18next.t("total_ror")}</p>
          <h5 className="info-content">
            {formatNumber(user?.total_ror, "0.00")}%
          </h5>
        </div>
      </div>
    </div>
  );
});

export default UserBalance;
