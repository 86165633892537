import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";

// icon
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";
import HeaderMobile from "_common/component/Layout/Header/HeaderMobile";
import UserBalance from "pages/Wallet/UserBalance";
import classNames from "classnames";
import Deposit from "pages/Wallet/Deposit/Deposit";
import WithdrawBank from "pages/Wallet/WithdrawBank/WithdrawSubmit";
import WithdrawSubmit from "pages/Wallet/Withdraw/WithdrawSubmit";
import Recharge from "pages/Wallet/Deposit/Recharge";
import Transfer from "pages/Wallet/Transfer";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, link_download, deposit_address, clear },
  } = useStores();
  const [active, setActive] = useState("deposit");

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("fc_frame")) {
        document.getElementById("fc_frame").style.display = "block";
      }
    }, 2000);

    return () => {
      if (document.getElementById("fc_frame")) {
        document.getElementById("fc_frame").style.display = "none";
      }
    };
  }, []);

  return (
    <>
      <HeaderMobile />
      <div className="member-page">
        <UserBalance />
        <div className="menu-list">
          <div className="tabs tab-head">
            <div
              className={classNames("tab", { active: active === "deposit" })}
              onClick={() => setActive("deposit")}
            >
              {i18next.t("deposit")}
            </div>
            <div
              className={classNames("tab", { active: active === "transfer" })}
              onClick={() => setActive("transfer")}
            >
              {i18next.t("transfer")}
            </div>
            <div
              className={classNames("tab", { active: active === "withdraw" })}
              onClick={() => setActive("withdraw")}
            >
              {i18next.t("withdraw")}
            </div>
          </div>
        </div>
        {active === "deposit" && <Recharge />}
        {active === "transfer" && <Transfer />}
        {active === "withdraw" && <WithdrawSubmit />}
        <ul className="memberMenu">
          <li>
            <Link to="/board/trade/history">
              <div>
                <span>{i18next.t("quick_location")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/feature/history">
              <div>
                <span>{i18next.t("contract_position")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/fund/history">
              <div>
                <span>{i18next.t("fund_history")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/history">
              <div>
                <span>{i18next.t("transaction_history")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          {/* <li>
            <Link to="/board/member/wallet">
              <div>
                <span>{i18next.t("wallet_address")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li> */}
          <li>
            <Link to="/board/member/sharing">
              <div>
                <span>{i18next.t("i_want_to_share")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member/introduction">
              <div>
                <span>{i18next.t("support_center")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <div
              className="link"
              onClick={() => {
                document.getElementById("chat-icon")?.click();
              }}
            >
              <div>
                <span>{i18next.t("online_customer_care")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </div>
          </li>
          <li>
            <Link to="/board/member/language">
              <div>
                <span>{i18next.t("language")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member" onClick={() => onLogout()}>
              <div>
                <span>{i18next.t("logout")}</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
});

export default MemberPage;
