import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";
import OptionGame from "./OptionGame";
import ContractGame from "./ContractGame";

const ModalBet = observer(({ open, onClose, type, game_id }) => {
  const [optionType, setOptionType] = useState("contract");

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <div className="tabs">
              <div
                className={classNames("tab", {
                  active: optionType === "contract",
                })}
                onClick={() => setOptionType("contract")}
              >
                Hợp đồng
              </div>
              <div
                className={classNames("tab", {
                  active: optionType === "option",
                })}
                onClick={() => setOptionType("option")}
              >
                Tuỳ chọn
              </div>
            </div>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            {optionType === "contract" && (
              <ContractGame onClose={onClose} game_id={game_id} type={type} />
            )}
            {optionType === "option" && (
              <OptionGame onClose={onClose} game_id={game_id} type={type} />
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
