import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

function Switch({ defaultValue, onChange, className }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(defaultValue);
  }, [defaultValue]);

  const handleChange = (item) => {
    if (typeof onChange === "function") {
      onChange(item);
    }
    setOpen(item);
  };

  return (
    <div
      className={classNames("van-switch", className, {
        "van-switch--on": open,
      })}
      onClick={() => handleChange(!open)}
    >
      <div className="van-switch__node" />
    </div>
  );
}

export default Switch;
