import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import KycFormFirst from "./KycFormFirst";

export default function KYCFirst() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  if (user?.is_verify === 3)
    return (
      <div className="container mt-5">
        <HeaderTop isBack title={i18next.t("Đang chờ xem xét")} />
        <div className="sbui-card-content success-content">
          <div className="model-content" id="content">
            <div className="icon">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="titles">Đang chờ xem xét</div>
            <div className="titles-info">
              Cảm ơn bạn đã gửi tài liệu KYC của mình. Nhóm của chúng tôi sẽ xem
              xét thông tin của bạn trong vòng 48 giờ. Hãy kiên nhẫn chờ đợi kết
              quả xem xét.{" "}
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container mt-5">
      <HeaderTop isBack title={i18next.t("primary_verification")} />
      <div className="sbui-card my-5">
        <div className="sbui-card-content">
          <KycFormFirst />
        </div>
      </div>
    </div>
  );
}
