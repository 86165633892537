import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function Dropdown({ value, children }) {
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={classNames("input-content  ", {
          "input-content-focus": active,
        })}
        aria-label="dropdown"
        type="button"
        onClick={() => setActive(!active)}
      >
        <span>{value}</span>
        <FontAwesomeIcon icon={active ? faAngleUp : faAngleDown} />
      </button>
      <div
        className={classNames("dropdown-menu dropdown-menu-end  ", {
          show: active,
        })}
        onClick={() => setActive(!active)}
      >
        {children}
      </div>
    </div>
  );
}

export default Dropdown;
