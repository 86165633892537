import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import icon1 from "assets/img/icon/strategy.svg";
import icon2 from "assets/img/icon/strategy2.svg";
import icon3 from "assets/img/icon/strategy3.svg";
import classNames from "classnames";
import FundDetail from "./FundDetail";

const FundBalance = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { display_balance, user, tokens },
  } = useStores();

  const [isTip, showTip] = useState(true);

  return (
    <div className="new-feature">
      <div className="left-bg1" />
      <div className="left-bg2" />
      <div className="titles">
        <FontAwesomeIcon icon={faWallet} />
        <span>{i18next.t("Hạn mức vay")}</span>
      </div>
      <div className="account-number">
        {formatNumber(user?.limit_fund, "0,0.[00]")} <span>USD</span>
      </div>
      {user?.is_verify !== 4 && (
        <>
          <div className="account-info">
            Xác minh danh tính và nhận được nhiều dịch vụ hơn!
          </div>
          <Link
            to="/board/member/kyc/first"
            type="button"
            className="start-button"
          >
            Bắt đầu xác minh
          </Link>
        </>
      )}

      <div className="hr" />
      <FundDetail />
    </div>
  );
});

export default FundBalance;
