import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { UserFundApi } from "states/api/userFund";
import { NotificationManager } from "react-notifications";
import Dropdown from "_common/component/Dropdown";

const schema = yup
  .object({
    amount: yup
      .number()
      .min(0, `${i18next.t("enter_large_amount_msg")} 0`)
      .required(i18next.t("verify_require")),
  })
  .required();

export default function FundDetail() {
  const navgate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const id = params?.id;
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();
  const [amount, setAmount] = useState(0);
  const [detail, setDetail] = useState({});

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
          type: "fund",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setDetail(get(data, "data[0]"));
  }, [data]);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    refetch();
    setValue("amount", 0);
  }, []);

  const { mutate, isLoading } = useMutation(
    (variables) => UserFundApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navgate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    trigger();
    const amount = values?.amount;

    if (Number(amount) > Number(detail?.max)) {
      return NotificationManager.error(
        `${i18next.t("enter_smaller_deposit_amount")} ${formatNumber(
          detail?.max
        )}`
      );
    }

    if (Number(amount) < Number(detail?.min)) {
      return NotificationManager.error(
        `${i18next.t("enter_large_deposit_amount")} ${formatNumber(
          detail?.min
        )}`
      );
    }

    mutate({
      ...values,
      fund_id: detail?.id,
    });

    return null;
  };

  const handleChange = (value) => {
    const cloneData = [...get(data, "data", [])];
    const itemFind = cloneData?.find((obj) => obj?.id === Number(value));
    setDetail(itemFind);
  };

  return (
    <form className="fund-detail" onSubmit={handleSubmit(onSave)}>
      <div className="title">Tạo AI lượng tử</div>
      <div>
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
          <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
            <div className="sbui-formlayout__label">
              {i18next.t("duration")}
            </div>
          </div>
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <Dropdown value={detail?.date ? `${detail?.date} Ngày` : null}>
                {data?.data?.map((item) => (
                  <div
                    className="item"
                    key={item?.id}
                    onClick={() => setDetail(item)}
                  >
                    <span>{item?.name}</span>
                    <span>
                      {formatNumber(item?.min_profit)}% -{" "}
                      {formatNumber(item?.max_profit)}%
                    </span>
                  </div>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
          <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
            <div className="sbui-formlayout__label">{i18next.t("amount")}</div>
          </div>
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <input
                type="number"
                inputMode="decimal"
                min={0}
                className="sbui-input sbui-input--medium"
                {...register("amount")}
              />
              <button
                type="button"
                className="btn btn-all"
                onClick={() => setValue("amount", user?.balance?.amount)}
              >
                <span>{i18next.t("btn_all")}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="infos">
            <div className="info">
              <p>{i18next.t("Tỷ suất sinh lời")}</p>
              <h5 className="info-content">
                {formatNumber(detail?.min_profit, "0.00")}% -{" "}
                {formatNumber(detail?.max_profit, "0.00")}%
              </h5>
            </div>
            <div className="info">
              <p>{i18next.t("total_profit")}</p>
              <h5 className="info-content">
                {formatNumber(detail?.min, "0.[00]")} -{" "}
                {formatNumber(detail?.max, "0.[00]")}
              </h5>
            </div>
          </div>
        </div>
        <div className="sbui-btn-container sbui-btn--w-full mt-6">
          <button type="submit" className="btn" disabled={!isValid}>
            <span>{i18next.t("confirm")}</span>
          </button>
        </div>
      </div>
    </form>
  );
}
