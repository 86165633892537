import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const HeaderTop = observer(({ isBack, isCenter, title }) => {
  const {
    authStore: { general, setLanguage, theme_light, logo },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [open_menu, setOpenMenu] = useState(false);

  const handleChangeTheme = () => {
    const styles = document.getElementsByTagName("html")[0].style;
    styles.setProperty(`color-scheme`, "light");
    document.getElementsByTagName("html")[0].className = "tw-dark light";
  };

  useEffect(() => {
    if (theme_light) {
      handleChangeTheme();
    }
  }, [theme_light]);

  const handleChangeLanguage = (item) => {
    setLanguage(item?.value);
  };

  return (
    <div
      className={classNames("nav_mobile_top", {
        is_center: isCenter,
      })}
    >
      <div className="nav_content">
        <div className="nav_left">
          {isBack && (
            <div className="back" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
          )}
          <div className="nav_name">{title}</div>
        </div>
      </div>
    </div>
  );
});

export default HeaderTop;
