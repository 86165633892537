import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Chart from "react-apexcharts";

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const ChartLine = observer(({ isUp }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    options: {
      chart: {
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        height: 44,
        offsetX: 0,
        offsetY: 0,
        parentHeightOffset: 0,
        redrawOnParentResize: false,
        redrawOnWindowResize: false,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "straight",
        colors: "#c0dd2b",
        width: 1,
      },
      grid: {
        show: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
    },
    series: [
      {
        data: [],
      },
    ],
  });

  useEffect(() => {
    setOptions((e) => ({
      ...e,
      options: {
        ...e.options,
        stroke: {
          ...e.options.stroke,
          colors: isUp ? "#c0dd2b" : "#ce7735",
        },
      },
    }));
  }, [isUp]);

  const randomData = () => {
    const result = [];
    for (let index = 0; index < 9; index += 1) {
      const number = randomIntFromInterval(20, 300);
      result.push(number);
    }
    setOptions((e) => ({
      ...e,
      series: [
        {
          data: result,
        },
      ],
    }));
  };

  useEffect(() => {
    randomData();
    const interval = setInterval(() => {
      randomData();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="markets-chart">
      <Chart
        className="line-chart"
        options={options.options}
        series={options.series}
        type="line"
        height={60}
      />
    </div>
  );
});

export default ChartLine;
