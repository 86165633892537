import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import i18next from "i18next";
import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
    password: yup.string().required(i18next.t("verify_require_password")),
    confirmed: yup
      .string()
      .required(i18next.t("verify_require_confirm_password"))
      .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
  })
  .required();

export default function RegisterForm({ type }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const ref_no = params?.get("ref_no");

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        // authStore.updateToken(res?.data);
        // authStore.updateUser(res?.data);
        // setTimeout(() => {
        //   navigate("/");
        // }, 200);
        setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  if (isSuccess) return <ActiveCode />;

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("username_login")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={
                      type === "phone"
                        ? i18next.t("verify_require_phone")
                        : i18next.t("verify_require_email")
                    }
                    className="sbui-input sbui-input--with-icon sbui-input--medium"
                    {...register("username")}
                  />
                  <div className="sbui-input-icon-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#666666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("password")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("password_input")}
                    type="password"
                    className="sbui-input sbui-input--with-icon sbui-input--medium"
                    {...register("password")}
                  />
                  <div className="sbui-input-icon-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#666666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("confirmed_password")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("confirmed_password")}
                    type="password"
                    className="sbui-input sbui-input--with-icon sbui-input--medium"
                    {...register("confirmed")}
                  />
                  <div className="sbui-input-icon-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#666666"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Number(authStore.general?.allow_ref_no) === 1 && (
            <div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("ref_text")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      disabled={!!ref_no}
                      type="text"
                      placeholder={i18next.t("ref_text")}
                      className="sbui-input sbui-input--with-icon sbui-input--medium"
                      {...register("ref_no")}
                    />
                    <div className="sbui-input-icon-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#666666"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="sbui-icon "
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="sbui-space-col sbui-space-y-6">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="btn"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <span>{i18next.t("register")}</span>
            </button>
          </span>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <span
            onClick={() => navigate("/auth/login")}
            className="text-primary px-4 py-2 mx-auto "
          >
            {i18next.t("note_20")}
          </span>
        </div>
      </div>
    </form>
  );
}
