import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import NotifyProvider from "_common/component/NotifyProvider";
import Maintenance from "_common/component/Maintenance";
import { MetaMaskUIProvider } from "@metamask/sdk-react-ui";
import MobileLayout from "./MobileLayout";

const BasicLayout = observer(() => {
  const {
    authStore: { logo, token, user, general },
  } = useStores();
  const { isMobile } = useMediaQuery();

  if (!logo) return <PageLoading />;
  if (!token) return <PageLoading />;
  if (!user?.id) return <PageLoading />;
  if (Number(general?.is_maintenance) === 1) return <Maintenance />;

  return (
    <NotifyProvider user={user}>
      <MetaMaskUIProvider
        debug={false}
        sdkOptions={{
          logging: {
            developerMode: false,
          },
          checkInstallationImmediately: false,
          dappMetadata: {
            name: "TradeLink",
            url: `${window.location.protocol}//${window.location.host}`,
          },
          infuraAPIKey: process.env.REACT_APP_INFURA_API_KEY,
          // Other options
        }}
      >
        <MobileLayout />
      </MetaMaskUIProvider>
    </NotifyProvider>
  );
});

export default BasicLayout;
