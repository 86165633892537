import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowRight,
  faPlusCircle,
  faUpDownLeftRight,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";

import saleCoin from "assets/img/icon/saleCoin.svg";
import trading from "assets/img/icon/trading.svg";
import icon5 from "assets/img/icon/icon5.png";
import icon6 from "assets/img/icon/legal_card.png";
import icon9 from "assets/img/icon/translate.png";

import banner2 from "assets/img/banner/banner2.jpg";
import { formatMoney, formatNumber } from "_common/utils/formatValue";
import HeaderMobile from "_common/component/Layout/Header/HeaderMobile";
import classNames from "classnames";
import i18next from "i18next";
import UserBalance from "pages/Wallet/UserBalance";
import ListCryptoData from "pages/Market/ListCryptoData";

const HomeUnAuth = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  const [active, setActive] = useState("code1");
  const [account, setAccount] = useState();

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinTopPrices = useMemo(
    () =>
      top_coin.map((item) => {
        const price = data?.find((obj) => obj?.symbol === item?.code);

        return { ...item, price };
      }),
    [top_coin, data]
  );

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <>
      <HeaderMobile />
      <div className="home-page">
        <UserBalance />
        <div className="top-coin">
          {coinTopPrices.map((item) => (
            <Link key={item?.code} to="/" className="item">
              <h5>
                <b>{item?.name}</b>
              </h5>
              <p>{item?.sub_name}</p>
              <h2
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                <b>
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}
                </b>
                <small>%</small>
              </h2>
              <div className="number-info-arrow">
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </Link>
          ))}
        </div>
        <div className="coin-list">
          <div className="list">
            <div className="item header">
              <div className="col1">
                <h2>{i18next.t("market")}</h2>
              </div>
              <div className="col2" />
              <div className="col3">
                <Link to="market">{i18next.t("view_more")}</Link>
              </div>
            </div>
            <ListCryptoData />
          </div>
        </div>
        <div className="strategy">
          <div className="strategy-icon">
            <img src={saleCoin} alt="" />
          </div>
          <div className="strategy-text">{i18next.t("comming_soon")}</div>
          <div className="strategy-text1">{i18next.t("strategy1")}</div>
          <div className="strategy-text">{i18next.t("strategy2")}</div>
          <Link
            to="/board/member"
            type="button"
            className="btn strategy-button"
          >
            <span>{i18next.t("btn_buy_now")}</span>
          </Link>
        </div>
        <div className="strategy">
          <div className="strategy-icon">
            <img src={trading} alt="" />
          </div>
          <div className="strategy-text">{i18next.t("strategy4")}</div>
          <div className="strategy-text1">{i18next.t("strategy5")}</div>
          <div className="strategy-text">{i18next.t("strategy6")}</div>
          <Link to="/board/fund" type="button" className="btn strategy-button">
            <FontAwesomeIcon icon={faPlusCircle} />
            <span>{i18next.t("btn_create_stagegy")}</span>
          </Link>
        </div>
      </div>
    </>
  );
});

export default HomeUnAuth;
