import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

const tabs = [
  {
    key: "1",
    text: i18next.t("holding"),
  },
  {
    key: "2",
    text: i18next.t("complete"),
  },
];

export default function HistoryTrade({ game_id }) {
  const [active, setActive] = useState(tabs[0].key);
  return (
    <div className="trade-history">
      <div className="menu-list px-2 mx-auto mt-5">
        <div className="tabs">
          {tabs.map((item) => (
            <div
              key={`item-${item.key.toString()}`}
              className={classNames("tab", { active: active === item.key })}
              onClick={() => setActive(item.key)}
            >
              {item.text}
            </div>
          ))}
        </div>
      </div>
      <table className="table-data">
        <thead>
          <th>
            <td>{i18next.t("product")}</td>
            <td className="text-right">
              {i18next.t("successful_transaction_price")}
            </td>
            <td>{i18next.t("direction")}</td>
            <td>{i18next.t("number_of_trading_lots")}</td>
            <td>{i18next.t("command_details")}</td>
          </th>
        </thead>
        <tbody />
      </table>
    </div>
  );
}
