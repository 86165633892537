import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import ListCryptoData from "./ListCryptoData";

const MarketPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, code1Coins, code2Coins, features, games, etf },
  } = useStores();

  const [active, setActive] = useState("usdt");

  return (
    <div className="market-page">
      <HeaderTop title={i18next.t("market")} isBack={false} />
      <div className="menu-list">
        <div className="tabs tab-head">
          <div
            className={classNames("tab", { active: active === "option" })}
            onClick={() => setActive("option")}
          >
            {i18next.t("Lưu trữ")}
          </div>
          <div
            className={classNames("tab", { active: active === "code1" })}
            onClick={() => setActive("code1")}
          >
            Ngoài hối
          </div>
          <div
            className={classNames("tab", { active: active === "code2" })}
            onClick={() => setActive("code2")}
          >
            Chức khoán mỹ
          </div>
          <div
            className={classNames("tab", { active: active === "feature" })}
            onClick={() => setActive("feature")}
          >
            Hợp đồng ngoại hối
          </div>
          <div
            className={classNames("tab", { active: active === "usdt" })}
            onClick={() => setActive("usdt")}
          >
            Tiền điện tử
          </div>
          <div
            className={classNames("tab", { active: active === "etf" })}
            onClick={() => setActive("etf")}
          >
            ETF
          </div>
        </div>
      </div>
      <div className="coin-list">
        <div className="list">
          {active === "option" && <ListCryptoData games={[]} />}
          {active === "code1" && <ListCryptoData games={code1Coins} />}
          {active === "code2" && <ListCryptoData games={code2Coins} />}
          {active === "feature" && <ListCryptoData games={features} />}
          {active === "usdt" && <ListCryptoData games={games} />}
          {active === "etf" && <ListCryptoData games={etf} />}
        </div>
      </div>
    </div>
  );
});

export default MarketPage;
