import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import MemberPage from "pages/Member";
import ListHistoryBalance from "pages/Wallet/ListHistoryBalance";
import Deposit from "pages/Wallet/Deposit/Deposit";

import HomeUnAuth from "pages/HomeUnAuth";
import WalletPage from "pages/Wallet";
import FundPage from "pages/Fund";
import ReportPage from "pages/Report";
import TradePage from "pages/Trade";
import MarketPage from "pages/Trade/ListCoin";
import MarketListPage from "pages/Market";
import Withdraw from "pages/Wallet/Withdraw/Withdraw";
import Recharge from "pages/Wallet/Deposit/Recharge";
import KYC from "pages/Member/KYC";
import UpdateUser from "pages/Member/UpdateUser";
import Security from "pages/Member/Security";
import Complain from "pages/Wallet/Complain";
import WithdrawSubmit from "pages/Wallet/Withdraw/WithdrawSubmit";
import ShareLink from "pages/Member/ShareLink";
import HistoryTradePage from "pages/Trade/HistoryTradePage";
import About from "pages/Member/About";
import Exchange from "pages/Wallet/Exchange";
import Introduction from "pages/Member/Introduction";
import FeaturePage from "pages/Feature";
import SpotTradePage from "pages/SpotTrade";
import KYCFirst from "pages/Member/KYCFirst";
import AddBankCard from "pages/Wallet/Bank/AddBankCard";
import CheckAuthHome from "routers/CheckAuthHome";
import UserInfoPage from "pages/Member/UserInfo";
import WalletPassword from "pages/Member/WalletPassword";
import IntroductionDetail from "pages/Member/IntroductionDetail";
import WithdrawBank from "pages/Wallet/WithdrawBank/WithdrawSubmit";
import Language from "pages/Member/Language";
import FundList from "pages/Fund/FundList";
import FundDetail from "pages/Fund/FundDetail";
import FundHistory from "pages/Fund/FundHistory";
import AddBankForm from "pages/Wallet/Bank/AddBankForm";
import HistoryFeaturePage from "pages/Feature/HistoryFeaturePage";
import SetTime from "pages/Member/SetTime";
import WalletAddress from "pages/Wallet/WalletAddress";
import WalletList from "pages/Wallet/WalletAddress/WalletList";
import LoanPage from "pages/Loan";

export function Routers() {
  return (
    <Routes>
      <Route
        path="/cskh"
        element={
          <MiddlewareRouter>
            <UnAuthLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Complain />} />
      </Route>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<HomeUnAuth />} />
        <Route path="home" element={<HomeUnAuth />} />
        <Route path="market" element={<MarketListPage />} />
      </Route>
      <Route
        path="/board"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<TradePage />} />
        <Route path="home" element={<TradePage />} />
        <Route path="member">
          <Route index element={<MemberPage />} />
          <Route path="info" element={<UserInfoPage />} />
          <Route path="kyc" element={<KYC />} />
          <Route path="kyc/first" element={<KYCFirst />} />
          <Route path="update-profile" element={<UpdateUser />} />
          <Route path="sharing" element={<ShareLink />} />
          <Route path="bank" element={<AddBankCard />} />
          <Route path="bank/submit" element={<AddBankForm />} />
          <Route path="about" element={<About />} />
          <Route path="wallet" element={<WalletList />} />
          <Route path="add-wallet" element={<WalletAddress />} />
          <Route path="language" element={<Language />} />
          <Route path="line" element={<SetTime />} />
          <Route path="introduction" element={<Introduction />} />
          <Route
            path="introduction/detail/:id"
            element={<IntroductionDetail />}
          />
          <Route path="security" element={<Security />} />
          <Route path="wallet-password" element={<WalletPassword />} />
        </Route>

        <Route path="wallet">
          <Route index element={<WalletPage />} />
        </Route>
        <Route path="fund">
          <Route index element={<FundPage />} />
          <Route path="list" element={<FundList />} />
          <Route path="history" element={<FundHistory />} />
          <Route path="detail/:id" element={<FundDetail />} />
        </Route>
        <Route path="loan">
          <Route index element={<LoanPage />} />
          <Route path="list" element={<FundList />} />
          <Route path="history" element={<FundHistory />} />
          <Route path="detail/:id" element={<FundDetail />} />
        </Route>
        <Route path="report">
          <Route index element={<ReportPage />} />
        </Route>
        <Route path="history">
          <Route index element={<ListHistoryBalance />} />
        </Route>
        <Route path="withdraw">
          <Route index element={<Withdraw />} />
          <Route path="submit" element={<WithdrawSubmit />} />
          <Route path="bank/submit" element={<WithdrawBank />} />
        </Route>
        <Route path="deposit">
          <Route index element={<Deposit />} />
        </Route>
        <Route path="exchange">
          <Route index element={<Exchange />} />
        </Route>
        <Route path="Recharge">
          <Route index element={<Recharge />} />
        </Route>
        <Route path="spot">
          <Route index element={<SpotTradePage />} />
        </Route>
        <Route path="feature">
          <Route index element={<FeaturePage />} />
          <Route path="history" element={<HistoryFeaturePage />} />
        </Route>
        <Route path="trade">
          <Route index element={<TradePage />} />
          <Route path="history" element={<HistoryTradePage />} />
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
