import React, { useEffect, useMemo, useState } from "react";

import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import i18next, { use } from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

export default function Recharge() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [symbol, setSymbol] = useState("usdc");
  const [network, setNetwork] = useState("trc20");

  const [address, setAddress] = useState();

  const {
    authStore: { deposit_address, coins },
  } = useStores();

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  return (
    <div className="content-info">
      <div className="title">{i18next.t("deposit")}</div>
      <div className="flex flex-col w-full gap-10  mt-6">
        <div className="flex flex-1">
          <div className="w-full h-full">
            <div className="flex justify-center mt-6 ">
              <select onChange={(e) => setSymbol(e?.target?.value)}>
                {deposit_address?.map((item) => (
                  <option
                    selected={item?.symbol === symbol}
                    key={item?.symbol}
                    value={item?.symbol}
                    onClick={(e) => setSymbol(item?.symbol)}
                  >
                    {item?.text}
                  </option>
                ))}
              </select>
            </div>
            {symbol === "usdt" && (
              <div className="radios-info">
                <div className="van-radio-group van-radio-group--horizontal">
                  <div
                    className="van-radio van-radio--horizontal"
                    onClick={() => setNetwork("trc20")}
                  >
                    <input type="radio" checked={network === "trc20"} />
                    <span className="van-radio__label">TRC20 </span>
                  </div>
                  <div
                    className="van-radio van-radio--horizontal"
                    onClick={() => setNetwork("erc20")}
                  >
                    <input type="radio" checked={network === "erc20"} />
                    <span className="van-radio__label">ERC20 </span>
                  </div>
                </div>
              </div>
            )}

            <div className="flex qr-code justify-center mt-6 ">
              {address && (
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    width: "140px",
                  }}
                  viewBox="0 0 256 256"
                  value={address}
                />
              )}
            </div>

            <form>
              <div className="flex flex-col w-full gap-4">
                <div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="text-gray-600 text-sm">
                        {i18next.t("coin_deposit_address")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          defaultValue={address}
                          type="text"
                          readOnly
                          className="sbui-input sbui-input--medium"
                        />
                        <button
                          type="button"
                          onClick={() => handleCopyLink(address)}
                          className="btn btn-copy"
                        >
                          <span>{i18next.t("copy")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="sbui-btn-container sbui-btn--w-full">
                  <button type="button" className="btn">
                    <span>{i18next.t("upload_bill")}</span>
                  </button>
                </span>
                <div className="sbui-btn-container sbui-btn--w-ful seed">
                  <p>{i18next.t("deposit_help")}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
