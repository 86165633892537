import React, { useEffect, useState } from "react";
import { get, omit } from "lodash";
import moment from "moment";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useStores } from "_common/hooks";
import { useForm } from "react-hook-form";

import { observer } from "mobx-react";
import { NotificationManager } from "react-notifications";
import { ProfileApi } from "states/api";
import UploadFile from "_common/component/UploadFile";
import i18next from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    identify_number: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const KycFormFirst = observer(() => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        navigate("/board/member/kyc");
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate({
        ...formValue,
      });
    }
  };

  useEffect(() => {
    if (!user?.full_name) return;
    setValue("full_name", user?.full_name);
    setValue("identify_number", user?.identify_number);
    setValue("type_kyc", user?.type_kyc);
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div className="flex flex-col items-center justify-between gap-4 mb-5">
        <div className="flex flex-col w-full">
          <div>
            <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("type_kyc")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <select
                    placeholder={i18next.t("type_kyc")}
                    className="sbui-input sbui-input--large"
                  >
                    <option value="cmnd">{i18next.t("id_card")}</option>
                    <option value="passport">{i18next.t("passport")}</option>
                    <option value="driver_license">
                      {i18next.t("license")}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div>
            <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("name")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    placeholder={i18next.t("name")}
                    type="text"
                    className="sbui-input sbui-input--large"
                    {...register("full_name")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div>
            <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("identification")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    placeholder={i18next.t("identification")}
                    type="text"
                    className="sbui-input sbui-input--large"
                    {...register("identify_number")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
        <div className="flex flex-col w-full">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              type="submit"
              className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
            >
              <span>{i18next.t("send")}</span>
            </button>
          </span>
        </div>
        <div className="flex items-center justify-between w-full" />
      </div>
    </form>
  );
});

export default KycFormFirst;
