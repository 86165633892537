import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import Switch from "_common/component/Switch";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";

const gameTypeList = [
  {
    time: 30,
    profit: 10,
    text: "30s",
  },
  {
    time: 60,
    profit: 20,
    text: "60s",
  },
  {
    time: 120,
    profit: 30,
    text: "120s",
  },
  {
    time: 300,
    profit: 40,
    text: "300s",
  },
  {
    time: 600,
    profit: 50,
    text: "600s",
  },
];

const ContractGame = observer(({ onClose, type, game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [optionType, setOptionType] = useState("contract");
  const [game_type, setGameType] = useState(30);
  const [amount, setAmount] = useState();
  const [price, setPrice] = useState(0);
  const [lever, setLevel] = useState(100);
  const [limitMin, setLimitMin] = useState(0);
  const [limitMax, setLimitMax] = useState(0);
  const [orderType, setOrderType] = useState(false);
  const [isStopLoss, setIsStopLoss] = useState(false);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        setAmount(Number(get(general, "bet_default", 0)));
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading) return null;
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    const itemType = gameTypeList?.find((obj) => obj?.time === game_type);

    let profit = 0;
    if (itemType) {
      const numRate = Number(itemType?.profit) / 100;
      profit = Math.floor(amount * numRate);
    }

    if (profit <= 0) {
      setLoading(false);
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    }

    onSubmit({
      amount,
      profit,
      type: game_type,
      bet_game: type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  useEffect(() => {
    if (orderType) return;
    setPrice(Number(formatNumber(dataGame?.lastPrice, "0.0000")));
  }, [dataGame?.lastPrice, orderType]);

  useEffect(() => {
    if (isStopLoss) return;
    const lastPrice = Number(get(dataGame, "lastPrice", 0), "0.0000");
    let gap = Number(lastPrice) * 0.05;
    if (type === "sell") {
      gap *= -1;
    }
    setLimitMin(Number(formatNumber(lastPrice + gap, "0.0000")));
    setLimitMax(Number(formatNumber(lastPrice - gap, "0.0000")));
  }, [dataGame?.lastPrice, type, isStopLoss]);

  return (
    <div className="contract-main">
      <div className="pross">
        <div className="pross-title">Đòn bẩy:64.00X </div>
        <div className="leverage-progress">
          <div className="progress" style={{ width: `calc(${lever}%)` }} />
          <div
            className={classNames("progress-circular progress-1x ", {
              "progress-circular-active": lever >= 1,
            })}
            onClick={() => setLevel(1)}
          />
          <div
            className={classNames("progress-circular progress-25x ", {
              "progress-circular-active": lever >= 25,
            })}
            onClick={() => setLevel(25)}
          />
          <div
            className={classNames("progress-circular progress-50x ", {
              "progress-circular-active": lever >= 50,
            })}
            onClick={() => setLevel(50)}
          />
          <div
            className={classNames("progress-circular progress-75x ", {
              "progress-circular-active": lever >= 75,
            })}
            onClick={() => setLevel(75)}
          />
          <div
            className={classNames("progress-circular progress-100x ", {
              "progress-circular-active": lever >= 100,
            })}
            onClick={() => setLevel(100)}
          />
          <div
            className="progress-circular progress-ring-active"
            style={{ left: `calc(${lever}%)` }}
          />
        </div>
        <div className="progress-identification">
          <div className="identification-1x">1X</div>
          <div className="identification-25x">25X</div>
          <div className="identification-50x">50X</div>
          <div className="identification-75x">75X</div>
          <div className="identification-100x">100X</div>
        </div>
      </div>
      <div className="entrust">
        <div>Lệnh giới hạn</div>
        <Switch
          defaultValue={orderType}
          onChange={(val) => setOrderType(val)}
        />
      </div>
      <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
        <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
          <div className="sbui-formlayout__label">{i18next.t("Giá mua")}</div>
        </div>
        <div className="sbui-formlayout__content-container-horizontal">
          <div className="sbui-input-container">
            <input
              type="number"
              inputMode="decimal"
              min={0}
              readOnly={!orderType}
              onChange={(e) => {
                setPrice(e?.target?.value);
              }}
              value={price}
              className="sbui-input sbui-input--medium"
            />
            <span className="option">
              <b className="text-uppercase">
                {game_id?.split("usdt")?.[0]}/USDT
              </b>
            </span>
          </div>
        </div>
      </div>
      <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
        <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
          <div className="sbui-formlayout__label">
            {i18next.t("Số lượng mua")}
          </div>
        </div>
        <div className="sbui-formlayout__content-container-horizontal">
          <div className="sbui-input-container">
            <input
              type="number"
              inputMode="decimal"
              min={0}
              onChange={(e) => {
                setAmount(e?.target?.value);
              }}
              value={amount}
              className="sbui-input sbui-input--medium"
            />
            <button
              type="button"
              className="btn btn-all sub"
              onClick={() => setAmount((el) => el >= 10 && el - 10)}
            >
              <span>-10</span>
            </button>
            <button
              type="button"
              className="btn btn-all add"
              onClick={() => setAmount((el) => el + 10)}
            >
              <span>+10</span>
            </button>
          </div>
          <div className="margins">
            <div>
              Margin yêu cầu:${formatNumber((amount * 1000) / (lever / 100))}
            </div>
            <div>
              Phí giao dịch:${formatNumber((amount * 10) / (lever / 100))}
            </div>
          </div>
        </div>
      </div>
      <div className="entrust">
        <div>Đặt lợi nhuận và dừng lỗ</div>
        <Switch
          defaultValue={isStopLoss}
          onChange={(val) => setIsStopLoss(val)}
        />
      </div>
      {isStopLoss && (
        <div className="input-content">
          <div className="input-lists">
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Giá lấy lợi nhuận")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="number"
                    inputMode="decimal"
                    min={0}
                    onChange={(e) => {
                      setLimitMin(e?.target?.value);
                    }}
                    value={limitMin}
                    className="sbui-input sbui-input--medium"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input-lists">
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("Giá dừng lỗ")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="number"
                    inputMode="decimal"
                    min={0}
                    onChange={(e) => {
                      setLimitMax(e?.target?.value);
                    }}
                    value={limitMax}
                    className="sbui-input sbui-input--medium"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        type="button"
        className="btn confirm-button"
        onClick={() => handleSubmit()}
      >
        {i18next.t("confirm")}
      </button>
    </div>
  );
});

export default ContractGame;
