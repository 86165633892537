import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import HistoryData from "./GameBoard/HistoryData";

export default function HistoryTradePage() {
  const [params, setParams] = useState({});
  return (
    <div className="container page-history  mt-10">
      <HeaderTop isBack title={i18next.t("quick_location")} />
      <HistoryData />
    </div>
  );
}
