import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import android from "assets/img/android.png";
import ios from "assets/img/ios.png";

export default function About() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("About")} />
      <div className="sbui-card my-10">
        <div className="sbui-card-content">
          <p className="text-center">V.1.0.0</p>
          <p className="text-center  mt-6">
            {i18next.t("download_app_android")}
          </p>
          <div className="flex justify-center  mt-2">
            <a
              href={general?.link_android}
              target="_blank"
              className="sbui-btn btn-download sbui-btn--text-align-center"
              rel="noreferrer"
              aria-label="link_android"
            >
              <img src={android} alt="" />
            </a>
          </div>
          <p className="text-center  mt-6">{i18next.t("download_app_ios")}</p>
          <div className="flex justify-center  mt-2">
            <a
              href={general?.link_ios}
              target="_blank"
              className="sbui-btn btn-download   sbui-btn--text-align-center"
              rel="noreferrer"
              aria-label="link_ios"
            >
              <img src={ios} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
