import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import icon1 from "assets/img/icon/strategy.svg";
import icon2 from "assets/img/icon/strategy2.svg";
import icon3 from "assets/img/icon/strategy3.svg";
import classNames from "classnames";

const UserFund = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { display_balance, user, tokens },
  } = useStores();

  const [isTip, showTip] = useState(true);

  return (
    <div className="new-feature">
      <div className="left-bg1" />
      <div className="left-bg2" />
      <div className="titles">
        <FontAwesomeIcon icon={faWallet} />
        <span>{i18next.t("Số tiền giao dịch AI")}</span>
      </div>
      <div className="banalce-info">
        <h5>{formatCurrency(user?.balance_fund)}</h5>
        <span className="ltr">{formatNumber(user?.balance?.change)}%</span>
      </div>
      <div className="infos">
        <div className="info">
          <p>{i18next.t("total_earning")}</p>
          <h5 className="info-content">
            {formatCurrency(user?.total_commission_today)}
          </h5>
        </div>
        <div className="info">
          <p>{i18next.t("total_profit")}</p>
          <h5 className="info-content">
            {formatCurrency(user?.total_commission)}
          </h5>
        </div>
      </div>
      <div className="big-buttons">
        <div className="button">
          <div className="button-icon">
            <img src={icon1} alt="" />
          </div>
          <div className="button-name">An toàn tài chính</div>
        </div>
        <div className="button">
          <div className="button-icon">
            <img src={icon2} alt="" />
          </div>
          <div className="button-name">Lợi nhuận ổn định</div>
        </div>
        <div className="button">
          <div className="button-icon">
            <img src={icon3} alt="" />
          </div>
          <div className="button-name">Dễ sử dụng</div>
        </div>
      </div>
      <div
        className={classNames("explain", {
          hide: isTip,
        })}
        onClick={() => showTip(!isTip)}
      >
        Giao dịch tự động là việc thực hiện tự động các chiến lược giao dịch
        bằng các chương trình và thuật toán máy tính. Phương pháp này sử dụng dữ
        liệu lịch sử, chỉ báo kỹ thuật, mô hình thống kê và các công cụ phân
        tích khác để xác định tín hiệu giao dịch và tự động chọn chúng để thực
        hiện. Định lượng giao dịch tự động có thể giúp các nhà giao dịch loại bỏ
        tác động cảm xúc, giảm giao dịch sai và cải thiện hiệu quả và tính nhất
        quán của giao dịch.
        {isTip && <span className="more">Khám phá thêm</span>}
      </div>
    </div>
  );
});

export default UserFund;
