import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useNavigate } from "react-router-dom";

import icon23 from "assets/img/icon/icon23.png";
import icon24 from "assets/img/icon/icon24.png";
import icon25 from "assets/img/icon/icon25.png";

import FundDetail from "./FundDetail";
import FundBalance from "./FundBalance";
import FundHistory from "./FundHistory";

const LoanPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("fund");

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleOpenModal = (value) => {
    setOpenModal(true);
    setType(value);
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  return (
    <div className="container fund-page">
      <HeaderTop title="Khoản vay" />
      <FundBalance />
      <div className="px-6">
        <h2 className="list-title">Lịch sử vay</h2>
        <FundHistory />
      </div>
    </div>
  );
});

export default LoanPage;
