import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

const tabs = [
  {
    key: "1",
    text: i18next.t("holding"),
  },
  {
    key: "2",
    text: i18next.t("complete"),
  },
];

export default function HistoryTrade({ game_id }) {
  const [active, setActive] = useState(tabs[0].key);
  const data = [];

  const renderBody = useMemo(() => {
    if (!data?.length)
      return (
        <div className="noRecord">
          <FontAwesomeIcon icon={faFileLines} />
        </div>
      );

    return (
      <table className="table-data">
        <thead>
          <th>
            <td>{i18next.t("product")}</td>
            <td className="text-right">
              {i18next.t("successful_transaction_price")}
            </td>
            <td>{i18next.t("direction")}</td>
            <td>{i18next.t("number_of_trading_lots")}</td>
            <td>{i18next.t("command_details")}</td>
          </th>
        </thead>
      </table>
    );
  }, []);

  return (
    <div className="trade-history">
      <h4>
        <span>{i18next.t("current_order")}(0)</span>
        <FontAwesomeIcon icon={faFileLines} />
      </h4>
      <div className="diver" />
      {renderBody}
    </div>
  );
}
