import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";

export default function HistoryData() {
  const [active, setActive] = useState("pending");

  const { data, refetch } = useQuery(
    ["game-player", "list", active],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          status: active,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [active]);

  return (
    <>
      <div className="menu-list px-2 mx-auto mt-5">
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: active === "pending",
            })}
            onClick={() => setActive("pending")}
          >
            {i18next.t("holding")}
          </div>
          <div
            className={classNames("tab", {
              active: active === "compete",
            })}
            onClick={() => setActive("compete")}
          >
            {i18next.t("compete")}
          </div>
        </div>
      </div>
      <div className=" px-2 mx-auto mt-5 w-full">
        <div className="list-history-data">
          {get(data, "data", [])?.map((item) => (
            <div className="item" key={item?.id}>
              <h5 className="text-md text-center text-blue-600">
                {i18next.t("time_to_sell")}:{" "}
                {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}{" "}
              </h5>
              <div className="flex  justify-between items-center gap-2 mt-2">
                <div className="flex flex-col  w-full">
                  <h4 className="text-xl font-bold">{item?.game?.name}</h4>
                  <p
                    className={classNames("btn-bet-game mb-2 mt-2", {
                      buy: item?.bet_game === "buy",
                      sell: item?.bet_game === "sell",
                    })}
                  >
                    {i18next.t(item?.bet_game)}
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("purchase_price")} : {formatNumber(item?.open)}
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("saleprice")} : {formatNumber(item?.close)}
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("command_time")} : {item?.type}s
                  </p>
                </div>
                <div className="flex flex-col items-end w-full">
                  <h4 className="text-xl font-bold">
                    {formatNumber(item?.amount)}
                    <small className="text-sm">usdt</small>
                  </h4>
                  <p className="text-sm text-black text-right">
                    {i18next.t("contract_profit_and_loss")}
                  </p>
                  {item?.status === "win" && (
                    <p className="text-sm text-green">
                      +{formatNumber(item?.amount_payback)}
                    </p>
                  )}
                  {item?.status === "lose" && (
                    <p className="text-sm text-red">
                      -{formatNumber(item?.amount_lose)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
