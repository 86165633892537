import React, { useEffect, useMemo, useState } from "react";

import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import i18next, { use } from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

export default function Transfer() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [symbol, setSymbol] = useState("usdc");
  const [network, setNetwork] = useState("trc20");

  const [address, setAddress] = useState();

  const {
    authStore: { deposit_address, coins },
  } = useStores();

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  return (
    <div className="content-info">
      <div className="title">{i18next.t("transfer")}</div>
      <div className="flex flex-col w-full gap-10  mt-6">
        <div className="flex flex-1">
          <div className="w-full h-full seed">
            <h5 className="list-title">
              Trước khi thực hiện chuyển khoản điện:
            </h5>
            <p>
              Trước khi thực hiện chuyển khoản điện, vui lòng liên hệ đội dịch
              vụ khách hàng của chúng tôi để có thông tin tài khoản chuyển khoản
              chính xác. Bước này đảm bảo an toàn và đến tài khoản của bạn một
              cách an toàn.
            </p>
            <h5 className="list-title">Thời gian xử lý chuyển khoản điện:</h5>
            <p>
              Trước khi thực hiện chuyển khoản điện, vui lòng liên hệ đội dịch
              vụ khách hàng của chúng tôi để có thông tin tài khoản chuyển khoản
              chính xác. Bước này đảm bảo an toàn và đến tài khoản của bạn một
              cách an toàn.
            </p>
            <h5 className="list-title">
              Hỗ trợ trong quá trình chuyển khoản điện:
            </h5>
            <p>
              Nếu bạn gặp bất kỳ vấn đề hoặc có câu hỏi nào trong quá trình
              chuyển khoản điện, hãy thoải mái liên hệ với đội dịch vụ khách
              hàng của chúng tôi. Chúng tôi cam kết hỗ trợ để đảm bảo trải
              nghiệm giao dịch mượt mà cho bạn.
            </p>
            <span className="sbui-btn-container sbui-btn--w-full mt-6">
              <button type="button" className="btn">
                <span>{i18next.t("contact_cskh")}</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
