import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";

export default function GameChart({ game_id, type, isLight }) {
  const {
    authStore: { trading_view },
  } = useStores();
  return (
    <div id="highcharts">
      <iframe
        title="chart"
        src={`${trading_view}?symbol=${game_id}&theme=${
          isLight ? "light" : "dark"
        }&color=rgb(27, 27, 27)&type=${type}`}
        width="100%"
        height="100%"
      />
    </div>
  );
}
