import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
    otp: yup.string(),
  })
  .required();

export default function WithdrawSubmit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [symbol, setSymbol] = useState("usdc");
  const [network, setNetwork] = useState("trc20");

  const {
    authStore: { user, general, coins, deposit_address },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const amount = useWatch({ control, name: "amount" });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === symbol),
    [user, symbol]
  );

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  return (
    <div className="content-info">
      <div className="title">{i18next.t("withdraw")}</div>
      <div className="flex flex-col w-full gap-10  mt-6">
        <div className="flex flex-1">
          <div className="sbui-card w-full h-full">
            <form className="sbui-card-content" onSubmit={handleSubmit(onSave)}>
              <div className="flex flex-col w-full gap-4">
                <div className="flex justify-center mt-6 ">
                  <select onChange={(e) => setSymbol(e?.target?.value)}>
                    {deposit_address?.map((item) => (
                      <option
                        selected={item?.symbol === symbol}
                        key={item?.symbol}
                        value={item?.symbol}
                        onClick={(e) => setSymbol(item?.symbol)}
                      >
                        {item?.text}
                      </option>
                    ))}
                  </select>
                </div>
                {symbol === "usdt" && (
                  <div className="radios-info">
                    <div className="van-radio-group van-radio-group--horizontal">
                      <div
                        className="van-radio van-radio--horizontal"
                        onClick={() => setNetwork("trc20")}
                      >
                        <input type="radio" checked={network === "trc20"} />
                        <span className="van-radio__label">TRC20 </span>
                      </div>
                      <div
                        className="van-radio van-radio--horizontal"
                        onClick={() => setNetwork("erc20")}
                      >
                        <input type="radio" checked={network === "erc20"} />
                        <span className="van-radio__label">ERC20 </span>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("coins_withdraw")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          type="number"
                          inputMode="decimal"
                          min={0}
                          className="sbui-input sbui-input--medium"
                          {...register("amount")}
                        />
                        <button
                          type="button"
                          className="btn btn-all"
                          onClick={() =>
                            setValue("amount", mainBalance?.amount)
                          }
                        >
                          <span>{i18next.t("btn_all")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <span className="block text-sm text-gray-500 mt-2">
                    {i18next.t("availability")}:{" "}
                    {formatBalance(mainBalance?.amount)} {mainCoin?.name}
                  </span>
                </div>
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("withdraw_coin_type")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        value={`${formatNumber(amount, "0,0.[000000]")} ${
                          mainCoin?.name
                        }`}
                        type="text"
                        readOnly
                        className="sbui-input sbui-input--medium"
                      />
                    </div>
                  </div>
                </div>
                <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                  <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                    <div className="sbui-formlayout__label">
                      {i18next.t("coin_withdraw_address")}
                    </div>
                  </div>
                  <div className="sbui-formlayout__content-container-horizontal">
                    <div className="sbui-input-container">
                      <input
                        type="text"
                        className="sbui-input sbui-input--medium"
                        {...register("address")}
                      />
                    </div>
                  </div>
                </div>
                <span className="sbui-btn-container sbui-btn--w-full">
                  <button type="submit" className="btn" disabled={!isValid}>
                    <span>{i18next.t("confirm")}</span>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
