import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderMobile from "_common/component/Layout/Header/HeaderMobile";
import { useStores } from "_common/hooks";

import General from "./General";
import UserBalance from "./UserBalance";

const WalletPage = observer(() => {
  const { authStore } = useStores();
  return (
    <div className="wallet-page">
      <General />
    </div>
  );
});

export default WalletPage;
