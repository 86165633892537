import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faSun,
  faMoon,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import ListCoin from "./ListCoin";

const listTime = [
  {
    value: "1m",
    text: "1m",
  },
  {
    value: "5m",
    text: "5m",
  },
  {
    value: "15m",
    text: "15m",
  },
  {
    value: "30m",
    text: "30m",
  },
  {
    value: "1h",
    text: "1H",
  },
  {
    value: "1d",
    text: "1D",
  },
  {
    value: "7d",
    text: "1W",
  },
  {
    value: "1m",
    text: "1M",
  },
];

export default function GameHeader({
  game_id,
  data_price,
  isLight,
  setIsLight,
  time,
}) {
  const navigate = useNavigate();
  const { dataGame } = useNotify();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="trade-header">
      {/* <div className="action-group">
        <div className="coin" onClick={() => setOpenModal(true)}>
          <span>
            <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
            <span>/USDT</span>
          </span>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <button
          type="button"
          className="btn btn-history"
          onClick={() => navigate("/board/trade/history")}
        >
          <FontAwesomeIcon icon={faFile} />
          <span>{i18next.t("hold_position")}</span>
        </button>
      </div> */}
      <div className="price-info">
        <div className="col1">
          <h1>{formatNumber(dataGame?.lastPrice, "0,0.00")}</h1>
          <h5
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) < 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            <span>
              {formatNumber(data_price?.priceChangePercent, "0,0.00")}%
            </span>
          </h5>
        </div>
        <div className="col2">
          <div>
            <p>{i18next.t("open_2")}</p>
            <h5>{formatNumber(data_price?.openPrice, "0,0.00")}</h5>
          </div>
          <div>
            <p>{i18next.t("shortest")}</p>
            <h5>{formatNumber(data_price?.lowPrice, "0,0.00")}</h5>
          </div>
          <div>
            <p>{i18next.t("tallest")}</p>
            <h5>{formatNumber(data_price?.highPrice, "0,0.00")}</h5>
          </div>
          <div>
            <p>{i18next.t("close_2")}</p>
            <h5>{formatNumber(data_price?.lastPrice, "0,0.00")}</h5>
          </div>
        </div>
      </div>
      <div className="chart-time">
        {listTime?.map((item) => (
          <button
            type="button"
            key={item.value}
            className={classNames("btn btn-time", {
              active: time === item?.value,
            })}
            onClick={() =>
              (window.location.href = `${window.location.pathname}?time=${
                item?.value
              }&symbol=${game_id}&theme=${isLight ? "light" : "dark"}`)
            }
          >
            {item.text}
          </button>
        ))}
      </div>
      <ListCoin open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}
