import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

const gameTypeList = [
  {
    time: 60,
    profit: 15,
    text: "60s",
  },
  {
    time: 180,
    profit: 30,
    text: "180s",
  },
  {
    time: 300,
    profit: 40,
    text: "300s",
  },
  {
    time: 600,
    profit: 50,
    text: "600s",
  },
  {
    time: 1800,
    profit: 70,
    text: "1800s",
  },
];

const OptionGame = observer(({ onClose, type, game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState(30);
  const [amount, setAmount] = useState();

  const profit = useMemo(() => {
    const itemType = gameTypeList?.find((obj) => obj?.time === game_type);
    let result = 0;
    if (itemType) {
      const numRate = Number(itemType?.profit) / 100;
      result = Math.floor(amount * numRate);
    }

    return result;
  }, [amount, game_type]);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        setAmount(Number(get(general, "bet_default", 0)));
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading)
      return NotificationManager.error(
        i18next.t("Có phiên giao dịch đang chạy")
      );
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));

    if (profit <= 0) {
      setLoading(false);
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    }

    onSubmit({
      amount,
      profit,
      type: game_type,
      bet_game: type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  return (
    <div className="contract-main">
      <div className="content">
        <div className="content-title ">
          <div className="content-title-left">
            <div className="left-number text-uppercase">
              {game_id?.split("usdt")?.[0]}/USD
            </div>
            <div className="left-icon left-icon1">
              {formatNumber(dataGame?.lastPrice, "0.00")}
            </div>
          </div>
        </div>

        <div className="list-bet">
          {gameTypeList.map((item) => (
            <div
              key={item.time}
              className={classNames("item", {
                active: item.time === game_type,
              })}
              onClick={() => setGameType(item.time)}
            >
              <h5>{item.text}</h5>
              <p>Tỷ suất sinh lời {formatNumber(item.profit, "0,0.00")}%</p>
            </div>
          ))}
        </div>
        <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
          <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
            <div className="sbui-formlayout__label">{i18next.t("Số tiền")}</div>
          </div>
          <div className="sbui-formlayout__content-container-horizontal">
            <div className="sbui-input-container">
              <input
                type="number"
                inputMode="decimal"
                min={0}
                onChange={(e) => {
                  setAmount(e?.target?.value);
                }}
                value={amount}
                className="sbui-input sbui-input--medium"
              />
            </div>
          </div>
        </div>
        <div className="item-row">
          <div className="item">
            <span className="text-black">{i18next.t("balance_available")}</span>
            <b>{formatNumber(usdtBalance?.amount, "0,0.[0000]")}</b>
          </div>
          <div className="item">
            <span>{i18next.t("Lợi nhuận dự kiến")}</span>
            <b>+{formatNumber(profit, "0,0.[0000]")}</b>
          </div>
          <div className="item">
            <span>{i18next.t("transaction_fee")}</span>
            <b>{formatNumber(amount / 100)}%</b>
          </div>
        </div>
        <button
          type="button"
          className="btn confirm-button"
          onClick={() => handleSubmit()}
        >
          {i18next.t("confirm")}
        </button>
      </div>
    </div>
  );
});

export default OptionGame;
