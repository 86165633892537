import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import titleicon from "assets/img/titleicon.png";
import SelectBox from "_common/component/SelectBox";
import DesktopSideBar from "../SideBar/DesktopSideBar";

const optionsData = [
  {
    value: "vi",
    label: "Tiếng việt",
  },
  {
    value: "en",
    label: "English",
  },
];

const HeaderMobile = observer(() => {
  const {
    authStore: { general, user, theme_light, logo },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [open_menu, setOpenMenu] = useState(false);

  return (
    <div className="nav_mobile_top">
      <div className="nav_content">
        <div className="nav_left">
          <div className="logo">
            <img src={logo} alt="/" />
          </div>
          <div className="nav_name">{general?.name_website}</div>
        </div>
        <div className="nav_right">
          <select>
            <option selected>{user?.id_display}</option>
          </select>
        </div>
      </div>
    </div>
  );
});

export default HeaderMobile;
