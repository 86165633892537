import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { fundStatus, loanStatus } from "_common/constants/statusType";
import moment from "moment";
import { get } from "lodash";
import { useStores } from "_common/hooks";

export default function FundHistory() {
  const navigate = useNavigate();
  const {
    authStore: { logo },
  } = useStores();
  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="list-history-data">
      {data?.data?.map((item) => (
        <div key={item?.id} className="item">
          <div className="flex  justify-between gap-2">
            <div className="flex items-center gap-4">
              <img src={logo} alt="" />
              <span className="text-lg font-bold">{item?.fund?.name}</span>
            </div>
            <button type="button" className="sbui-btn  btn-fund-add">
              {i18next.t(loanStatus[item?.status])}
            </button>
          </div>
          <div className="flex  justify-between items-center gap-2 mt-5">
            <div className="flex flex-col items-center">
              <h4 className="text-sm text-center">
                {i18next.t("investment_amount")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.amount)}
              </span>
            </div>
            <div className="flex flex-col items-center">
              <h4 className="text-sm text-center">
                {i18next.t("Tiền lãi hàng ngày")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.fund?.min_profit, "0,0.[000]")}%
              </span>
            </div>
            <div className="flex flex-col items-center">
              <h4 className="text-sm text-center">{i18next.t("game_no")}</h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.fund?.date, "0")}({i18next.t("day")})
              </span>
            </div>
          </div>
          <div className="flex  justify-between items-center gap-2 mt-5">
            <div className="flex flex-col items-center w-full">
              <h4 className="text-sm text-center">{i18next.t("start_day")}</h4>
              <span className="text-sm   text-center text-blue-500">
                {moment(item?.created_at).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className="flex flex-col items-center w-full">
              <h4 className="text-sm text-center">
                {i18next.t("finish_time")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {moment(item?.created_at)
                  .add(Number(get(item, "fund.date", 0)), "d")
                  .format("YYYY-MM-DD")}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
