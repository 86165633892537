import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";
import ChartLine from "./ChartLine";

const ListCryptoData = observer(({ games = [] }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [gameList, setGameList] = useState(games);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 20,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  return (
    <div>
      {coinListPrice?.map((item) => (
        <Link
          className="item"
          to={`/board/trade?symbol=${item?.code}`}
          key={item?.id}
        >
          <div className="col1">
            {item?.image_url && <img src={item?.image_url} alt="" />}
            <div className="market-name">
              <h5 className="name">{item?.name}</h5>
              {item?.sub_name && <p>{item?.sub_name}</p>}
            </div>
          </div>
          <div className="col2">
            <ChartLine isUp={item?.price?.priceChangePercent > 0} />
          </div>
          <div className="col3">
            <h5>{formatNumber(item?.price?.lastPrice, "0,0.[00000]")}</h5>
            <p
              className={classNames({
                "text-green": item?.price?.priceChangePercent > 0,
                "text-red": item?.price?.priceChangePercent <= 0,
              })}
            >
              {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
});

export default ListCryptoData;
