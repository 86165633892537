import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const UnAuthLayout = observer(() => {
  const navigate = useNavigate();

  return (
    <div className="mobile-layout">
      <Outlet />
    </div>
  );
});

export default UnAuthLayout;
